import React, { useState } from "react";
import { Link } from "react-router-dom";

function Header() {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [showServicesMenu, setShowServicesMenu] = useState(false);
    return (
        <header className="bg-3f-black fixed w-full z-50">
            <nav>
                <div className="hidden md:grid max-w-7xl m-auto grid-cols-3 py-5">
                    <div className="justify-self-start flex flex-row relative col-span-2">
                        <div className="flex flex-col justify-center text-white hover:text-dd-brown text-2xl px-4">
                            <Link to="/">Top Line Kitchen And Bath</Link>
                        </div>
                        <div className="flex flex-col justify-center text-white text-xl px-4"
                            onMouseEnter={() => setShowServicesMenu(true)}
                            onMouseLeave={() => setShowServicesMenu(false)}
                            >
                            <div className={showServicesMenu ? "text-dd-brown" : "text-white"}>Services</div>
                            <div className={`absolute top-10 bg-3f-black p-2 border-dd-brown border-b-4 ${showServicesMenu ? "flex flex-col" : "hidden"}`}>
                                <div><Link to="/remodeling" className="hover:text-dd-brown">Remodeling</Link></div>
                                <div><Link to="/flooring" className="hover:text-dd-brown">Flooring</Link></div>
                                <div><Link to="/custom-tile" className="hover:text-dd-brown">Custom Tile</Link></div>
                                <div><Link to="/bathroom" className="hover:text-dd-brown">Bathroom</Link></div>
                                <div><Link to="/kitchen" className="hover:text-dd-brown">Kitchen</Link></div>
                            </div>
                        </div>
                        <div className="flex flex-col justify-center text-white hover:text-dd-brown text-xl px-4">
                            <Link to="/reviews">Reviews</Link>
                        </div>
                        <div className="flex flex-col justify-center text-white hover:text-dd-brown text-xl px-4">
                            <Link to="/contact">Contact Us</Link>
                        </div>
                    </div>  
                    <div className="justify-self-end flex flex-row">
                        <div className="flex flex-col justify-center pr-10">
                            <div className="hover:text-dd-brown hover:underline hover:cursor-pointer text-white text-xl ">530-845-0140</div>
                        </div>
                        <Link to="/contact" className="bg-dd-brown p-3 text-white font-semibold">Contact Us</Link>
                    </div>
                </div>
                <div className="flex flex-row md:hidden h-14 w-full justify-end">
                    <button type="button"
                        className=" inline-flex items-center justify-center rounded-md p-2.5 text-white"
                        onClick={() => setIsNavOpen((prev) => !prev)}
                        >
                        <span className="sr-only">Open main menu</span>
                        <svg className="h-6 w-6 justify-self-end" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                    </button>

                </div>
            </nav>
            <div className="h-2 bg-dd-brown w-full"></div>
            <div className={`lg:hidden ${isNavOpen ? "" : "hidden"}`} role="dialog" aria-modal="true">
                <div className="fixed inset-0 z-50"></div>
                <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <Link to="/" className="-m-1.5 p-1.5">
                            <img className="w-10 float-left"
                                src="./logo2.png"
                                alt=""/>
                            <span className="float-right pt-2">Top Line Kitchen & Bath</span>
                        </Link>
                        <button type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setIsNavOpen(false)}>
                            <span className="sr-only">Close menu</span>
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <Link to="/" onClick={() => setIsNavOpen(false)} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Home</Link>
                                <Link to="/remodeling" onClick={() => setIsNavOpen(false)} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Remodeling</Link>
                                <Link to="/flooring" onClick={() => setIsNavOpen(false)} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Flooring</Link>
                                <Link to="/custom-tile" onClick={() => setIsNavOpen(false)} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Custom Tile</Link>
                                <Link to="/bathroom" onClick={() => setIsNavOpen(false)} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Bathroom</Link>
                                <Link to="/kitchen" onClick={() => setIsNavOpen(false)} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Kitchen</Link>
                                <Link to="/reviews" onClick={() => setIsNavOpen(false)} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Reviews</Link>
                                <Link to="/contact" onClick={() => setIsNavOpen(false)} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
